import $ from 'jquery';
import lozad from 'lozad';

require("popper.js")
require("bootstrap")
require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")


require("trix")
require("@rails/actiontext")

$(function() {
  const asyncImg = lozad('.js-async-img', { rootMargin: '400px 0px' });
  asyncImg.observe();

  import('pages/front').then(({ default: Pages }) => Pages());
})
